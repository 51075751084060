export default {
  color: {
    WHITE: '#FFF',
    BLACK: '#000',
    BLACK690: '#00000069',
    MAIN_BG: '#161C24',
    SECONDARY_BG: '#212B36',
    BG_HEADER: '#63409A',
    PRIMARY: '#63409A',
    SECOUNDARY: '#C9DC44',
    GREY: '#141414',
    GREY100: '#b4b4b4',
    GREY300: '#8D8D8D',
    GREY500: '#707070',
    GREY550: '#545454',
    GREY600: '#3D3D3D',
    GREY625: '#2A2A2A',
    GREY650: '#252525',
    GREY700: '#262626',
    GREY_INPUT: '#4F4F4F',
    BLUE: '#4F63F0', //#3b5998
    BLUE500: '#6274F6',
    ELETRIC_VIOLET: '#5121F4',
    GREEN: '#2A9235',
    GREEN_SUCCESS: '#00a656',
    GREENCHECK: '#2EF142',
    YELLOW: '#FFFF00',
    RED_FAIL: '#f95252',
    YELLOW_ALERT: '#664d03',
    YELLOW_ALERT_CLEAN: '#fff3cd',
    DARK_YELLOW: '#f4c154',
    BRONZE: '#CD7F32',
  },
  border: {
    radius: {
      SHARP: 0,
      ROUND: '50%',
    },
  },
  media: {
    // BREAKPOINTS
    XS: 0,
    SM: '36rem',
    MD: '48rem',
    LG: '62rem',
    XL: '75rem',
    XXL: '87.5rem',
  },
  font: {
    family: {
      MAIN: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
      SECONDARY: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
      TITLE: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
      ICON: `'Material Design Icons', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
      JOIN: `'DM Sans'`,
      TERTIARY: `'Prompt'`,
      IBM: `'IBM Plex Mono'`,
      SANS: `'IBM Plex Sans'`,
    },
    weight: {
      LIGHT: 300,
      REGULAR: 400,
      MEDIUM: 500,
      SEMI_BOLD: 600,
      BOLD: 700,
      EXTRA_BOLD: 800,
      FULL_BOLD: 900,
    },
    size: {
      XXXSMALL: '0.8rem',
      XXSMALL: '1rem',
      XSMALL: '1.2rem',
      SMALL: '1.4rem',
      MEDIUM: '1.6rem',
      LARGE: '1.8rem',
      XLARGE: '2.0rem',
      XXLARGE: '2.8rem',
      XXXLARGE: '4rem',
    },
  },
}
