import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .react-modal-overlay {
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      position: fixed;
      top: 0;
      outline: 0;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 15px;
      z-index: 99999999;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-modal-classname {
      width: 100%;
      max-width: 760px;
      outline: 0;
      background: #f3f6fa;
      padding: 20px;
      position: relative;
      border-radius: 0.75rem;
      box-shadow: 0px 3px 14px #00000029;

      &.modal-news {
        background: ${theme.color.BLACK};
        border: 1px solid ${theme.color.PRIMARY};
        color: ${theme.color.WHITE};
      }

      &.modal-xp {
        max-width: 366px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background: ${theme.color.GREY650};
      }

      &.modal-rocket {
        max-width: 532px;
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
      }
    }

    .ReactModal__Content {
      opacity: 0;
      transform: translate(0, -50px);
      transition: all 500ms ease-in-out;
    }

    .ReactModal__Content--after-open {
      opacity: 1;
      transform: none;
    }

    .ReactModal__Content--before-close {
      opacity: 0;
      transform: translate(0, -50px);
    }

    body {
      margin: 0;
      padding: 0;

      font-family: 'IBM Plex Mono', monospace;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${theme.color.MAIN_BG};
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #242424;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #1b1b1b;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box !important;
    }

    ::-moz-selection {
      background: #ffff00;
      color: black;
    }
    ::selection {
      background: #ffff00;
      color: black;
    }
  `}
`

export default GlobalStyles
