import { useEffect, useState, useCallback } from 'react'
import router from 'next/router'
import jwtDecode from 'jwt-decode'
import { log } from '../utils/Utils'

interface MyTokenProps {
  name: string
  exp: number
}

export const useGetValidToken = (tokenParam: string) => {
  const [token, setToken] = useState<string>(tokenParam)

  const currentTime = (): number => {
    return Date.now() / 1000
  }

  const tokenValidation = (token: string) => {
    if (token === '' || token === undefined) {
      log('JWT_DECODE TOKEN INPUT IS EMPTY')
      //router.push('/login')
      return
    }
    try {
      const { exp }: MyTokenProps = jwtDecode(token)

      log('JWT_DECODE EXP = ', exp, currentTime())
      if (!token || exp < currentTime()) {
        log('JWT_DECODE TOKEN EXPIRED')
        // TODO recupear um refresh token e validar uma nova sessão para o user
        router.push('/login')
      } else {
        log('JWT_DECODE IS OK')
      }
    } catch (err) {
      log('JWT_DECODE_CATCH_ERROR', err)
    }
  }

  useEffect(() => {
    log('JWT_DECODE CALL TOKEN = ', token)
    tokenValidation(token)
  }, [token])

  const tokenValidationCallback = useCallback((tokenParam: string) => {
    log('ValidToken::CALLBACK', tokenParam)
    tokenValidation(tokenParam)
  }, [])

  return [tokenValidationCallback] as const
}
