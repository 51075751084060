import { createContext, ReactNode, useState } from 'react'

type AuthContextData = {
  nome: string
  modalXP: boolean
  openModalXP: () => void
  closeModalXP: () => void
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const [modalXP, setModalXP] = useState(false)

  const openModalXP = () => {
    setModalXP(true)
  }

  const closeModalXP = () => {
    setModalXP(false)
  }

  return (
    <AuthContext.Provider
      value={{ nome: 'Luis F', modalXP, openModalXP, closeModalXP }}
    >
      {children}
    </AuthContext.Provider>
  )
}
