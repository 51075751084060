import { AuthProvider } from 'context/AuthContext'
import { TokenProvider } from 'context/TokenContext'
import { AppProps } from 'next/app'

import Modal from 'react-modal'

import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import GlobalStyles from 'styles/global'
import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { UserProvider } from 'context/UserContext'

import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { pageView } from 'utils/Google/gtm'
import { useRouter } from 'next/router'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

Modal.setAppElement('#__next')

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView)

    return () => {
      router.events.off('routeChangeComplete', pageView)
    }
  }, [router.events])

  useEffect(() => {
    TagManager.initialize({
      gtmId: String(process.env.NEXT_PUBLIC_GTM_ID_ENGLISH),
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <TokenProvider>
        <UserProvider>
          <AuthProvider>
            <GlobalStyles />
            <ToastContainer
              theme="dark"
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Component {...pageProps} />
          </AuthProvider>
        </UserProvider>
      </TokenProvider>
    </ThemeProvider>
  )
}

export default App
