import { useGetValidToken } from 'hooks/useGetValidToken'
import { createContext, useState, ReactNode } from 'react'
import { log } from 'utils/Utils'
import GamificationPlayer from './TokenContext'

type UserContextData = {
  studentId: string
  name: string
  email: string
  phone: string
  level: string
  token: string
  logged: boolean
  hasDiagnostic: boolean
  recordedCourseActive: boolean
  liveClassActive: boolean
  mentoringActive: boolean
  diagnosticId: string
  trialType: string
  toeflActive: boolean
  setIsLogged: (val: boolean) => void
  isLogged: () => boolean
  update: () => void
  tokenRefresh: () => void
  npsEnabled: boolean
  hasProfiles: boolean
  onboardingCompleted: boolean
  gamificationPlayer: GamificationPlayer | undefined
}

export const UserContext = createContext({} as UserContextData)

type UserProviderProps = {
  children: ReactNode
}

export function UserProvider({ children }: UserProviderProps) {
  const [studentId, setStudentId] = useState<string>('')
  const [nome, setNome] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [level, setLevel] = useState<string>('')
  const [logged, setLogged] = useState<boolean>(false)
  const [hasDiagnostic, setHasDiagnostic] = useState<boolean>(false)
  const [recordedCourseActive, setRecordedCourseActive] = useState<any>(null)
  const [liveClassActive, setLiveClassActive] = useState<any>(null)
  const [mentoringActive, setMentoringActive] = useState<any>(null)
  const [diagnosticId, setDiagnosticId] = useState<string>('')
  const [trialType, setTrialType] = useState<string>('')
  const [toeflActive, setToeflActive] = useState<boolean>(false)
  const [npsEnabled, setNpsEnabled] = useState<boolean>(false)
  const [hasProfiles, setHasProfiles] = useState<boolean>(false)
  const [onboardingCompleted, setOnboardingCompleted] = useState<boolean>(false)
  const [gamificationPlayer, setGamificationPlayer] =
    useState<GamificationPlayer>()

  const [tokenValidationCallback] = useGetValidToken(token)

  // não é a melhor opção pq o contexto reseta ao recarregar a página
  const setIsLogged = (val: boolean) => {
    setLogged(val)
    configureUserDetails()
  }

  const isLogged = () => {
    const getLocalStorage = localStorage.getItem('userInfo')

    if (getLocalStorage != null || getLocalStorage != undefined) {
      const userDetails = JSON.parse(String(getLocalStorage))

      const {
        id,
        name,
        email,
        phone,
        token,
        level,
        hasDiagnostic,
        recordedCourseActive,
        liveClassActive,
        mentoringActive,
        diagnosticId,
        trialType,
        toeflActive,
        npsEnabled,
        hasProfiles,
        onboardingCompleted,
        gamificationPlayer,
      } = userDetails

      setStudentId(id || 'id empty')
      setNome(name || 'Guest')
      setEmail(email || '')
      setPhone(phone || '')
      setToken(token || 'token empty')
      setLevel(level || 'BEGINNER')
      setHasDiagnostic(hasDiagnostic || false)
      setRecordedCourseActive(recordedCourseActive || false)
      setLiveClassActive(liveClassActive || false)
      setMentoringActive(mentoringActive || false)
      setDiagnosticId(diagnosticId || '')
      setNpsEnabled(npsEnabled || '')
      setHasProfiles(hasProfiles || '')
      setOnboardingCompleted(onboardingCompleted || '')
      setGamificationPlayer(gamificationPlayer || '')

      log('userDetails', userDetails)

      setTrialType(trialType || '')
      setToeflActive(toeflActive || false)
      return true
    }

    return false
  }

  function configureUserDetails() {
    const userDetails = JSON.parse(String(localStorage.getItem('userInfo')))
    setStudentId(userDetails?.id || 'id empty')
    setNome(userDetails?.name || 'Guest')
    setEmail(userDetails?.email || '')
    setPhone(userDetails?.phone || '')
    setToken(userDetails?.token || 'token empty')
    setLevel(userDetails?.level || 'BEGINNER')
    setHasDiagnostic(userDetails?.hasDiagnostic || false)
    setRecordedCourseActive(userDetails?.recordedCourseActive || false)
    setLiveClassActive(userDetails?.liveClassActive || false)
    setMentoringActive(userDetails?.mentoringActive || false)
    setDiagnosticId(userDetails?.diagnosticId || '')
    setTrialType(userDetails?.trialType || false)
    setToeflActive(userDetails?.toeflActive || false)
    setNpsEnabled(userDetails?.npsEnabled || false)
    setHasProfiles(userDetails?.hasProfiles || false)
    setOnboardingCompleted(userDetails?.onboardingCompleted || false)
    setGamificationPlayer(userDetails?.gamificationPlayer || '')
  }

  const update = () => {
    configureUserDetails()
  }

  const tokenRefresh = () => {
    const userDetails = JSON.parse(String(localStorage.getItem('userInfo')))
    tokenValidationCallback(userDetails?.token)
  }

  return (
    <UserContext.Provider
      value={{
        studentId: studentId,
        name: nome,
        email: email,
        phone: phone,
        level: level,
        token: token,
        logged: logged,
        hasDiagnostic: hasDiagnostic,
        recordedCourseActive: recordedCourseActive,
        liveClassActive: liveClassActive,
        mentoringActive: mentoringActive,
        diagnosticId: diagnosticId,
        trialType: trialType,
        toeflActive: toeflActive,
        npsEnabled: npsEnabled,
        hasProfiles: hasProfiles,
        onboardingCompleted: onboardingCompleted,
        gamificationPlayer: gamificationPlayer,
        setIsLogged,
        isLogged,
        update,
        tokenRefresh,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
