declare global {
  interface Window {
    dataLayer: {
      event: string
      pagePath?: string
      pageTitle?: string
    }[]
  }
}

export function pageView(url: string): void {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'pageview',
    pagePath: url,
    pageTitle: document.title,
  })
}
