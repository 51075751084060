import { createContext, ReactNode, useState } from 'react'
import axios from 'axios'

// qual boa pratica para reaproveitar os types?
type UserDetails = {
  id: string
  name: string
  email: string
  phone: string
  token: string
  level: string
  wizard: boolean
  hasDiagnostic: boolean
  profile: Profile
  recordedCourseActive: boolean
  liveClassActive: boolean
  mentoringActive: boolean
  diagnosticId: string
  trialType: string
  toeflActive: boolean
  npsEnabled: boolean
  hasProfiles: boolean
  onboardingCompleted: boolean
  gamificationPlayer: GamificationPlayer
}

type Profile = {
  id: string
  name: string
  authorities: Authority[]
}

type Authority = {
  id: string
  name: string
}

type TokenContextData = {
  // token validation and return userDetails
  validateToken(token: string): Promise<UserDetails>
}

type TokenProviderProps = {
  children: ReactNode
}

type GamificationPlayer = {
  level: number
  totalXp: number
  nextLevelXp: number
  progress: number
}

export default GamificationPlayer

export const TokenContext = createContext({} as TokenContextData)

export function TokenProvider({ children }: TokenProviderProps) {
  const [userInfo, setUserInfo] = useState<UserDetails>()

  const validateToken = async (token: string) => {
    const profile: Profile = { id: '', name: '', authorities: [] }
    const gamificationPlayer: GamificationPlayer = {
      level: 0,
      progress: 0,
      totalXp: 0,
      nextLevelXp: 0,
    }
    const userDetails: UserDetails = {
      id: '',
      name: '',
      email: '',
      phone: '',
      token: '',
      level: '',
      wizard: false,
      hasDiagnostic: false,
      profile: profile,
      recordedCourseActive: false,
      liveClassActive: false,
      mentoringActive: false,
      diagnosticId: '',
      trialType: '',
      toeflActive: false,
      npsEnabled: false,
      hasProfiles: false,
      onboardingCompleted: false,
      gamificationPlayer: gamificationPlayer,
    }

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    const response = await axios.get(
      `${process.env.ENGLISH_BACKEND_URL}/home/student`
    )

    const data = response.data
    if (data) {
      gamificationPlayer.level = data?.playerGamification?.level || 0
      gamificationPlayer.progress = data?.playerGamification?.progress || 0
      userDetails.id = data?.id || null
      userDetails.name = data.name
      userDetails.email = data.email
      userDetails.phone = data.phone
      userDetails.token = token
      userDetails.level = data?.level || null
      userDetails.wizard = data?.wizardDone
      userDetails.hasDiagnostic = data?.hasDiagnostic || false
      userDetails.recordedCourseActive = data?.recordedCourseActive || false
      userDetails.liveClassActive = data?.liveClassActive || false
      userDetails.mentoringActive = data?.mentoringActive || false
      userDetails.diagnosticId = data?.diagnosticId || null
      userDetails.trialType = data?.trialType || ''
      userDetails.toeflActive = data?.toeflActive || false
      userDetails.npsEnabled = data?.npsEnabled || false
      userDetails.hasProfiles = data?.hasProfiles || false
      userDetails.onboardingCompleted = data?.onboardingCompleted || false
      userDetails.gamificationPlayer = gamificationPlayer

      profile.id = data.profile.id
      profile.name = data.profile.name
      userDetails.profile = profile // testar se ele altera por referência, se sim remover essa linha
      localStorage.setItem('userInfo', JSON.stringify(userDetails))
      setUserInfo(userDetails)

      // fixme: pensar numa forma dos localStorages não ficarem espalhados assim!!!
      // esse cara é utilizado na home page
      localStorage.removeItem('nps_submitted')

      return userDetails
    }

    return userDetails
  }

  return (
    <TokenContext.Provider value={{ validateToken }}>
      {children}
    </TokenContext.Provider>
  )
}
