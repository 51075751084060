export const getUrlParam = (name: string) => {
  const url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const log = (message: string, ...data: any) => {
  if (process.env.NODE_ENV === 'development') {
    if (data !== undefined) {
      console.log(message, data)
    } else {
      console.log(message)
    }
  }
}

export const getNameFirstLetter = (name: string) => {
  const splittedName = name.split(' ')
  const splitLength = splittedName.length

  return splitLength > 1
    ? splittedName[0].substring(0, 1) +
        splittedName[splitLength - 1]?.substring(0, 1).toUpperCase()
    : splittedName[0].substring(0, 1).toUpperCase()
}

export const abbreviateName = (name: string, sizeToAbreviate: number) => {
  const trimmedName = name.trim()
  const nameSplitted = name.split(' ')
  if (
    sizeToAbreviate >= 6 &&
    name.length >= sizeToAbreviate &&
    nameSplitted.length > 1
  ) {
    return `${nameSplitted[0]} ${nameSplitted[nameSplitted.length - 1]}`
  } else return trimmedName
}
